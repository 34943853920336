// Extend SAYT so that we can have arbitrary inputs and outputs since we're
// communicating directly with the ES REST API and not a Smallbox AJAX handler.
function ssSAYT(selector, options, selectAction) {
    SAYT.call(this, selector, options, selectAction);
}

ssSAYT.prototype = Object.create(SAYT.prototype);
ssSAYT.prototype.constructor = ssSAYT;

function initSayt(form_id, input_id)
{
    var search_host = $(input_id).data('searchHost');
    var search_index = $(input_id).data('searchIndex');
    var search_down_url = $(input_id).data('search-down-url');

    var options = {
        'url': '//' + search_host + '/search/ac',
        'searchDownUrl': search_down_url,
        'appendTo': form_id,
        'fieldName': '',
        'inputOnSelect': 'set'
    };

    var ss = new ssSAYT(input_id, options, function (li, result) {
        if (result && result.url.length) {
            window.location.href = result.url;
            return false;
        }

        $(input_id).val(result.title);
        $(input_id).closest('form').submit();
        return true;
    });

    // Typing speed limiter
    var $_typing_timeout = null;
    $(input_id).off('input').on('input', function(evt) {
        var code = evt.keyCode;

        if (this.value == ss.lastValue) {
            return;
        }

        ss.lastValue = this.value;

        if (!$_typing_timeout) {
            ss.request();
            $_typing_timeout = setTimeout(function() {
                clearTimeout($_typing_timeout);
                $_typing_timeout = null;
                ss.request();
            }, 300);
        }
    });
}

ssSAYT.prototype.clear = function()
{
    this.sayt.stop(true, true).fadeOut('fast');
};

ssSAYT.prototype.request = function()
{
    var self = this;

    if (this.options.populateOnEmpty === false && this.input.val().length === 0) {
        this.clear();
        return;
    }

    if (this.last == this.input.val()) {
        if (self.sayt.children(".sayt-results").length) {
            self.sayt.stop(true, true).fadeIn('fast');
        }
        return;
    }

    this.last = this.input.val();

    if (this._xhr) {
        this._xhr.abort();
    }

    if (this.options.request) {
        this.options.request(this);
    }

    let search_string = self.input.val();
    let search_url = this.options.url + '/' + search_string;

    this._xhr = $.get(search_url, {})
    .done(function(data) {
        var sayt = self.sayt;
        var input = self.input;

        function reorderChildren() {
            var node_id = 0, parent_id = 0, type = 0;

            Object.keys(self.results).forEach(function(result_id) {
                let result = self.results[result_id];

                node_id = result._source.node_id;
                parent_id = result._source.parent_id;
                type = result._source.type;

                if (
                    (type == 2)
                    && (self.results['ID-' + parent_id])
                    && (self.results['ID-' + parent_id]._source.type == 3)
                ) {
                    self.results['ID-' + parent_id]._children = {};
                    self.results['ID-' + parent_id]._children['ID-' + node_id] = result;
                    delete self.results[result_id];
                }
            });
        }

        self._xhr = null;

        self.results = {};

        // Build our results from the raw data
        data.hits.hits.forEach(function(result) {
            self.results['ID-' + result._source.node_id] = {
                'url': result._source.url,
                'title': result._source.title,
                '_source': result._source
            };
        });

        self.selected = null;

        if (Object.keys(self.results).length === 0) {
            if (self.options.showNoResults) {
                self.sayt.children().remove();
                self.sayt.append("<div class=\"sayt-no-results\">No Results</div>");
            } else {
                self.clear();
            }
        } else {
            if (self._timeout) {
                clearTimeout(self._timeout);
            }

            reorderChildren();

            self.sayt.stop(true, true).fadeIn('fast');
            self.input.removeClass('sayt-has-value');
            
            self.sayt.children().remove();
            self.sayt.append("<div class=\"sayt-results\"><ul></ul></div>");
            var ul = $("ul", sayt);

            Object.keys(self.results).forEach(function(result_id) {
                let result = self.results[result_id];
                function append_result(result, is_child = false) {
                    var li_append = '<span class="search-link' + (is_child ? " child" : "") + '"><a href="' + result.url + '">' + result.title + '</' + 'a></' + 'span>';
                    $('<li class="search-result" data-result-url="' + result.url + '">')
                        .append(li_append)
                        .appendTo(ul);
                    ul.children().last().data('result', result);
                }

                append_result(result);

                if (result._children) {
                    Object.keys(result._children).forEach(function(result_id) {
                        let child = result._children[result_id];
                        append_result(child, "child");
                    });
                }
            });
        }
    })
    .fail(function(data) {
        if ((typeof data.readyState === "undefined") || data.readyState !== 4) {
            return false;
        }
        $.post(self.options.searchDownUrl, {
            headers: data.getAllResponseHeaders(),
            responseText: data.responseText
        });
        alert("Search is temporarily unavailable.");
    });
};

jQuery.fn.extend({
    highlight: function(keyword, class_name)
    {
        class_name = class_name || 'search-keyword';
        var replacement = '<span class="' + class_name + '">' + keyword + '</span>';
        var regexp = new RegExp('(<span class="' + class_name + '">|\b)?' + keyword.replace(/(?=[()])/g, '\\') + '(</span>|\b)?', 'i');

        $(this).find(':not(iframe,script)').addBack().contents().filter(function() {
            return this.nodeType == 3 && regexp.test(this.nodeValue) && !$(this).parents('svg').length;
        }).replaceWith(function() {
            return (this.nodeValue || "").replace(regexp, function(keyword) {
                return "<span class=\"" + class_name + "\">" + keyword + "</span>";
            });
        });
    }
});
